.containerFooter{
    background: linear-gradient(to bottom,black, black, black, black);
}
.Navlinks {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    min-height: 254px;
    background-color: transparent;
    transition: background-color .2s ease;
}
.icon-size {
    color: rgb(233, 233, 209);
    font-size: 4rem;
    transition: color 1s ease-out;
    /* Transition for icon color */
}

/* Hover effect for social icons */
.Navlinks:hover {
    background-color: rgb(233, 233, 209);
}

.Navlinks:hover .icon-size {
    color: black;
}


.nav-link {
    font-size: 1rem;
    /* transition: transform 0.5s ease, color 0.5s ease; */
}
.nav-item{
    margin-right: 20px;
    margin-bottom: 5px;
}
.nav-link:hover {
    /* transform: scale(1);  */
    color: rgb(233, 233, 209);
}

.Logo:hover {
    filter: drop-shadow(1px 1px 30px rgb(188, 109, 34));
}

.links {
    color: rgb(233, 233, 209);
    width: 450px;

}
.containerFooter {
    justify-content: space-around;
    gap: 50px;

}

.textbox {
    width: 500px;
    gap: 20px;

    /* height: 25vh; */
}
.Logo {
    width: '50%';
    height: '50%';
    object-fit: cover;
    transition: all 0.5s ease-in-out 0s;
}
@media screen and (max-width: 600px) {
    .containerFooter {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 20px;
        border:none;
        background: linear-gradient(to bottom,transparent,rgba(0,0,0,0.8),black, black, black, black);
    }
    .textbox {
        text-align: center;
        padding: 10px;
        gap:0;
        height: fit-content;
        width: -webkit-fill-available;
    }
    .Navlinks {
        min-height: 100px;
    }
    .buttons {
        width: 100%;
        justify-content: space-around;
        padding: 10px 0;
        flex-direction: row;
        gap: 10px;
        margin: auto;
        border: none;
        /* Adjust social icon size */
        border-bottom: 2px solid rgb(134, 122, 83);
        border-top: 2px solid rgb(134, 122, 83);
    }

    .buttons .Navlinks:nth-child(4),
    .buttons .Navlinks:nth-child(5) {
        display: none;
    }

    .nav-item {
        text-align: center;
        width: 100%;
    }

    .nav-link {
        font-size: 16px;
    }

    .links {
        padding-top: 0em;
        padding-bottom: 0.5em;
    }
}

@media screen and (min-width: 600px) and (max-width: 1300px) {
    .containerFooter {
        flex-direction: column;
        /* Change to column layout */
        align-items: center;
        /* Center the items in column view */
        padding: 10px;
        gap: 20px;
    }

    .textbox {
        width: 100%;
        /* Full width for smaller screens */
        text-align: center;
        /* border-bottom: 2px solid rgb(233, 233, 209); */
        padding: 20px;
        width: auto;
        gap: 50px;

    }

    .buttons {
        width: 100%;
        justify-content: space-around;
        padding: 10px 0;
        flex-direction: row;
        gap: 10px;
        margin: auto;
        border: none;
        /* Adjust social icon size */
        border-bottom: 2px solid rgb(134, 122, 83);
        border-top: 2px solid rgb(134, 122, 83);
        /* width: fit-content; */
        /* margin-left: 400px; */
    }

    .buttons .Navlinks:nth-child(4),
    .buttons .Navlinks:nth-child(5) {
        display: none;
        /* Hide Twitter and Facebook */
    }

    .nav-item {
        text-align: center;
        width: 100%;
    }

    .nav-link {
        font-size: 1.2rem;
        margin: 10px 0;
    }

    .links {
        width: 100vw;
        color: rgb(233, 233, 209);
    }
}
