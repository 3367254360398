@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);

/* Removed animation */
.social img {
  padding-top: 0px;
  width: 100%;
}

.cent {
  align-items: center;
  justify-content: center;
  display: flex;
}

.pic {
  width: 35px;
  margin: 0px 15px;
}

.whole {
  height: 100%;
  background-color: #fff7d3;
}

.udgh23 {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 800;
}

.packed {
  margin: auto;
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Raleway", Arial, sans-serif;
  flex-wrap: wrap;
}

.card {
  width: 500px;
  min-height: 700px; /* Increase min-height to accommodate text */
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  background: #fff;
  cursor: pointer;
  margin: 50px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Ensure text and image stack */
}

.imgbox {
  width: 100%;
  height: auto; /* Adjust height dynamically based on content */
  z-index: 10;
}

.content {
  font-size: large;
  margin-top: 20px; /* Add some space between image and text */
  flex-grow: 1; /* Ensures content takes up available space */
}

.content .tit {
  color: #7f9ead;
  font-size: x-large;
  line-height: normal;
  color: #2473b9;
}

/* Media Query for mobile responsiveness */
@media (max-width: 780px) {
  .card {
    height: auto;
    overflow: hidden;
    width: 80%;
  }

  .content {
    margin-top: 50px;
  }

  .pic {
    width: 30px;
    margin: 0px 15px;
  }

  .cent {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .whole {
    height: 100%;
    background-color: #fff7d3;
  }

  .social img {
    padding-top: 0px;
    width: 100%;
    height: 102vh;
    visibility: hidden;
  }

  .parrot {
    background: url(https://live.staticflickr.com/65535/54036179164_890984ffb5_z.jpg) 50% 40% no-repeat;
  }
}
