#body{
    /* background-image: url("https://live.staticflickr.com/65535/53230486205_01702f0806_c.jpg"); */
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-attachment: fixed;
    overflow: hidden;
}

#H_2022 {
  display: flex;
  flex-direction: row;
}

#H_2022::before,
#H_2022::after {
  content: "";
  flex: 0.3 0.3;
  border-bottom: 2px solid #d9d9d9;
  margin: auto;
}

.container_2022{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m_gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.m_gallery > *{
    width: 15em !important;
    transition: width 0.5s;
}

.m_gallery > *:hover{
    width: 25em !important;
}

.galleryDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.galleryDiv > *{
    margin-inline: 0.5em !important;
}

.img-box1{
    width: 7em;
    height: 20em;
    margin: 1em;
    border-radius: 2em;
    background: url("https://live.staticflickr.com/65535/53013014507_f8e5004835_z.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    transition: width 0.5s;
}

.img-box1:nth-child(2){
    background: url("https://live.staticflickr.com/65535/53014086058_342e270d31_z.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:nth-child(3){
    background: url("https://live.staticflickr.com/65535/52398025399_2536e72402_z.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:nth-child(4){
    background: url("https://live.staticflickr.com/65535/52398025594_1c5678581e_z.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:nth-child(5){
    background: url("https://live.staticflickr.com/65535/53229864239_c2f2bca418_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:nth-child(6){
    background: url("https://live.staticflickr.com/65535/53229986255_f74cd88674_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:nth-child(7){
    background: url("https://live.staticflickr.com/65535/52397739716_0c1414a337_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:nth-child(8){
    background: url("https://live.staticflickr.com/65535/53229791458_812e66ba29_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box1:hover{
    width: 15em;
}

.img-box2{
    width: 7em;
    height: 20em;
    margin: 1em;
    border-radius: 2em;
    background: url("https://live.staticflickr.com/65535/53229852714_9b310a71e2_b.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    transition: width 0.5s;
}

.img-box2:nth-child(2){
    background: url("https://live.staticflickr.com/65535/53229852654_d51654525e_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:nth-child(3){
    background: url("https://live.staticflickr.com/65535/53229782858_39640139d4_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:nth-child(4){
    background: url("https://live.staticflickr.com/65535/53229975895_188f22c466_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:nth-child(5){
    background: url("https://live.staticflickr.com/65535/53229475406_164f4dbe7d_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:nth-child(6){
    background: url("https://live.staticflickr.com/65535/53228603112_4370ba192a_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:nth-child(7){
    background: url("https://live.staticflickr.com/65535/53229975335_df9bebe218_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:nth-child(8){
    background: url("https://live.staticflickr.com/65535/53228602967_6b61b9a57f_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box2:hover{
    width: 15em;
}

.img-box3{
    width: 7em;
    height: 20em;
    margin: 1em;
    border-radius: 2em;
    background: url("https://live.staticflickr.com/65535/53234595139_2efb9c5543_b.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    transition: width 0.5s;
}

.img-box3:nth-child(2){
    background: url("https://live.staticflickr.com/65535/53234721290_0a34d91f03_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:nth-child(3){
    background: url("https://live.staticflickr.com/65535/53234221281_76273a8a85_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:nth-child(4){
    background: url("https://live.staticflickr.com/65535/53234522863_0657aa0336_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:nth-child(5){
    background: url("https://live.staticflickr.com/65535/53234595034_bdc2b0a918_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:nth-child(6){
    background: url("https://live.staticflickr.com/65535/53234522828_bbc919c813_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:nth-child(7){
    background: url("https://live.staticflickr.com/65535/53234594979_ed4ea2e4e1_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:nth-child(8){
    background: url("https://live.staticflickr.com/65535/53234221156_129671cf2d_b.jpg");
    background-size: cover;
    background-position: center;
}

.img-box3:hover{
    width: 15em;
}

#H_2021 {
  display: flex;
  flex-direction: row;
}

#H_2021::before,
#H_2021::after {
  content: "";
  flex: 0.3 0.3;
  border-bottom: 2px solid #d9d9d9;
  margin: auto;
}

.container_2021{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#H_2020 {
  display: flex;
  flex-direction: row;
}

#H_2020::before,
#H_2020::after {
  content: "";
  flex: 0.3 0.3;
  border-bottom: 2px solid #d9d9d9;
  margin: auto;
}

.container_2020{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vid-box3{
    object-fit: cover;
    position: absolute;
    overflow: hidden;
    width: 15em;
    height: 20em;
    margin: 1em;
    border-radius: 2em;
    position: relative;
    transition: width 0.5s;
    z-index: 10;
}

.vid-box3:hover{
    width: 25em;
}

#background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.m_v_gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.m_v_gallery > *{
    width: 15em !important;
    border-radius: 1.5em;
    transition: width 0.5s;
}

.m_v_gallery > *:hover{
    width: 25em !important;

}