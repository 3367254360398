.cardAfterMovies{
    width:400px;
    height: 65vh;
}
.cardAfterMovies:hover{
    cursor: pointer;
}
.cardAfterMovies::before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    width: 40%;
    height: 100%;
    top: 0;
    left: -150%;
    transform: skew(45deg);
    transition: left 600ms ease-out;
}

.cardAfterMovies:hover::before {
    left: 180%;
}
.youtubeIcon{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0s 0.5s; 
}
.youtubeIcon img{
    width:80px; 
    transition: all 0.5s ease-in-out;
}
.cardAfterMovies:hover .youtubeIcon{
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0s 0s;
 }
.heading h1{
    font-size: 48px;
}
.afterMovies{
    flex-direction: column;
}
.afterMoviesHeading {
    font-family: 'Samarkan';
}

.horse2{
    filter: invert(0.7);
    width: 148px;
    transition: all 0.5s ease;
}
.horse2:hover{
 transform: scale(1.1);
}

@media (min-width: 1200px){
    .afterMovies{
        flex-direction: row;
    }
}
@media (min-width: 1050px) and (max-width: 1200px){
    .cardAfterMovies{
        width:33vw;
        height: 60vh;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .afterMovies{
        flex-direction: row;
    }
    .heading h1{
        font-size: 56px;
    }
}
@media (min-width: 600px) and (max-width: 1050px){
    .cardAfterMovies{
        width:33vw;
        height: 56vh;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
    }
    .afterMovies{
        flex-direction: row;
        flex-wrap: wrap;
    }
}
@media(max-width: 600px){
    .cardAfterMovies{
        width:65vw;
        height: 33vh;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .anchorTag{
        width: 65vw;
    }
    .cardAfterMovies:nth-child(1){
        margin-left: 0;
        margin-right: 100px;
    }
    .anchorTag:nth-child(1){
        margin-left: 0;
        margin-right: 100px;
    }
    .cardAfterMovies:nth-child(2){
        margin-left: 100px;
        margin-right: 10px;
    }
    .anchorTag:nth-child(2){
        margin-left: 100px;
        margin-right: 10px;
    }
    .anchorTag:nth-child(2) img{
        object-position: 0%;
    }
    .cardAfterMovies:nth-child(3){
        margin-left: 0;
        margin-right: 100px;
    }
    .anchorTag:nth-child(3){
        margin-left: 0;
        margin-right: 100px;
    }
    .heading h1{
        font-size: 44px;
    }
    .afterMoviesHeading{
        /* font-size: 32px; */
        font-size: 45px;
    }
    .horse2{
    width:64px
    }
}