.other-container {
    position: relative;
    background-color:transparent;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-weight: 600;
    min-height: 120vh;
    /* border: 20px dashed red; */
}

.other-upper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
}

.other-lower {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.other-element {
    width: 26%;
    margin-bottom: 50px;
    margin-top: 50px;
}

.otherHeading {
    font-family: 'Samarkan';    
}

.other-img {
    width: 100%;
    transition: 0.8s;
    border-radius: 10px;
}

.other-img:hover {
    scale: 1.1;
    transition: 0.8s;
    box-shadow: 0px 0px 30px rgb(188, 135, 2);
}

.other-element p {
    margin-top: 15px;
}

.horse1 {
    filter: invert(0.7);
    width: 208px;
    transition: all 0.5s ease;
}
.horse1:hover{
 transform: scale(1.1);
}

@media(max-width: 1200px) {
    .other-container {
        flex-direction: column;
        justify-content: space-around;
    }

    .other-upper {
        flex-direction: row;
        width: 100%;
        padding: 20px;
    }

    .other-element {
        width: 30%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media(min-width: 1200px) {
    .other-container {
        height: 100vh;
    }
}

@media(max-width: 600px) {
    .other-container {
        flex-direction: column;
        min-height: 10vh;
    }

    .other-upper {
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .other-element {
        width: 47%;
    }

    .otherHeading {
        font-size: 45px !important;
    }

    .horse1 {
        width: 64px;
    }
    .horse1:hover{
     transform: scale(1.1);
    }
}