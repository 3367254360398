@import url('https://fonts.googleapis.com/css?family=Orbitron');
@import url('https://fonts.googleapis.com/css?family=Kodchasan');

.newabout-container{
    height: fit-content;
    background-color: transparent;
    position: relative;
}

.about24{
    width: 100%;
    height: 120px;
    align-content: center;
}
.about24 h{
    font-size: 50px;
    text-align: center;
    align-self: center;
    font-weight: 700;
    color:white;
    /* text-shadow:  0 0 1em rgb(228, 228, 228), 0 0 0.2em grey; */
    font-family: 'Samarkan';
}
.newabout-written{
    border-radius: 50px;
    backdrop-filter: blur(3px);
    display: flex;
    
    align-items: center;
    justify-content:space-around;
    margin-top: 50px;
}
.iframe iframe{
    width: 530px;
     height: 300px; 
    border-radius: 10px; /* Rounded corners */
    /* box-shadow: 0 8px 16px rgba(145, 123, 123, 0.2),  */
                /* 0 12px 40px rgba(216, 218, 166, 0.1);  */
                box-shadow: 5px 5px 20px rgb(30, 30, 30);
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
     
}

.newabout-text{
    padding: 20px;
    color: white;
    font-weight: 500;
    width: 50%;
    text-align: left;
    
}
.newabout-all-tokens{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 50px;
}
.newabout-token {
    border-radius: 20px;
    width: 30%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-weight: 500;
   /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
}



.newabout-img {
    width: 40%;
    transition: transform 0.3s ease;
}

.newabout-token:hover .newabout-img {
    transform: scale(1.1); /* Image grows slightly on hover */
}

.newabout-data-one {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

.newabout-token span {
    color: #FFA4A4;
    font-family: 'Orbitron', sans-serif;
    font-size: 1.2em; /* Larger number */
    font-weight: 700;
}

.newabout-token p {
    padding: 5px;
    font-family: 'Kodchasan', sans-serif;
    font-size: 1.2em;
    font-weight: 500;
}

.newabout-line {
    height: 5px;
    width: 50%;
    background-color: white;
    /* border-radius: 2px; */
    /* margin: 10px 0;  */
}

.newabout-data-one{
    display: flex;
}
#webgi-canvas{
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
#webgi-canvas:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}


/* .iframe:hover {
    transform: translateY(-5px); 
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3), 
                0 18px 50px rgba(0, 0, 0, 0.15); 
} */



@media(max-width: 1600px){
    .newabout-token{
        width: 28%;
    }
}
@media(max-width: 1200px){
    .newabout-container{
         height: 110vh; 
    }
    .iframe iframe{
        
         margin-left: 30px; 
    }
    .newabout-back-down{
        height: 80vh;
    }
    .newabout-content{
        height: 90vh;
    }
    .newabout-about{
        flex-direction: column;
        align-items: center;
    }
    .newabout-trophy{
        width: 100%;
        height: 70vh;
    }
    .newabout-main-content{
        width: 100%;
    }
    .newabout-text{
        color: beige;
    }
    .newabout-token{
        width: 24%;
    }
    .about-data-name{
        font-size: larger;
    }
}
@media (min-width: 800px) and (max-width: 900px){
    .iframe iframe{
        width: 400px;
         height: 200px;
         
    }
   
     .newabout-written{
        margin-bottom: 0px ;
     }
    .newabout-img{
        /* width: 30%; */
        height: 50%;
    }
    .newabout-data-one{
        flex-direction: column;
        align-items: center;
    }
    .newabout-events-img{
        width: 50%;
    }
    .newabout-trophy{
        width: 100%;
        height: 50vh;
    }
    .newabout-back-down{
        height: 100vh;
    }
    .newabout-main-content{
        height: fit-content;
    }
    .newabout-back{
        height: fit-content;
    }
    .newabout-content{
        height: fit-content;
    }
}

@media (min-width: 601px) and (max-width: 799px){
    .newabout-container{
        height: fit-content;
    }
    .iframe iframe{
        width: 400px;
         height: 200px; 
         margin-bottom: 25px;
            margin-left: 30px; 
       
    }
    .newabout-written{
       flex-direction: column;
    }
    .newabout-text{
        width: 90%;
        text-align: center;
    }

    
    .newabout-data-one{
        flex-direction: column;
        align-items: center;
    }
    .newabout-events-img{
        width: 50%;
    }
    .newabout-trophy{
        width: 100%;
        height: 50vh;
    }
    .newabout-back-down{
        height: 100vh;
    }
    .newabout-main-content{
        height: fit-content;
    }
    .newabout-back{
        height: fit-content;
    }
    .newabout-content{
        height: fit-content;
    }
}



@media (max-width: 600px) and (min-width:425px) {
    .newabout-container{
        height: fit-content;
    }

    .about24{
        font-size: 45px!important;
    }
    .newabout-all-tokens{
        flex-direction: column;
        align-items: center;
    }
    .newabout-written{
        margin-top: 20px;
        flex-direction: column;
        gap:50px;
        align-items: center;
     }
     .iframe iframe{
        width: 400px;
        height: 200px;
        margin-right: 25px;
     }
     .newabout-text{
         width: 90%;
         text-align: center;
     }
    .newabout-token{
        margin: 15px 0px ;
        width: 75%;
        height: 20vh;
        flex-direction: row;
    }
    .newabout-line{
        height: 70%;
        width: 4px;
    }
    .newabout-img{
        /* width: 30%; */
        height: 50%;
    }
    .newabout-data-one{
        flex-direction: column;
        align-items: center;
    }
    .newabout-events-img{
        width: 50%;
    }
    .newabout-trophy{
        width: 100%;
        height: 50vh;
    }
    .newabout-back-down{
        height: 100vh;
    }
    .newabout-main-content{
        height: fit-content;
    }
    .newabout-back{
        height: fit-content;
    }
    .newabout-content{
        height: fit-content;
    }
    .iframe{
        width: 95%;
        height: 220px;
    }
}
@media (max-width:425px) {
    .newabout-content{
        height: fit-content;
    }
    .newabout-container{
        height: fit-content;
    }
    .newabout-all-tokens{
        flex-direction: column;
        align-items: center;
        padding: 0;
        /* margin-top: 180px; */
    }
    .newabout-written{
        /* margin-top: 20px; */
        flex-direction: column;
        gap:50px;
        align-items: center;
     }
     .iframe iframe{
        width: 80%;
        height: 150px;
        margin-right: 20px;
     }
     .newabout-text{
         width: 90%;
         text-align: center;
     }
    .newabout-token{
        /* margin: 15px 0px ; */
        width: 75%;
        /* height: 20vh; */
        flex-direction: row;
        height: 20vh;
    }
    .newabout-line{
        display: none!important;
    }
    .newabout-img{
        /* width: 30%; */
        height: 50%;
    }
    .newabout-data-one{
        flex-direction: column;
        align-items: center;
    }
    .newabout-events-img{
        width: 50%;
    }
    .newabout-trophy{
        width: 100%;
        height: 50vh;
    }
    .newabout-back-down{
        height: 100vh;
    }
    .newabout-main-content{
        height: fit-content;
    }
    .newabout-back{
        height: fit-content;
    }
    .newabout-content{
        height: fit-content;
    }
    .iframe{
        width: 100%;
    }
}