.gallery{   
    background-color: transparent;
    overflow-x: hidden;
    height:90vh;
}
/* .sliderButton{
    opacity: 1;
    transition: opacity 0.5s ease-in;
}
.sliderButton:hover{
    opacity: 1;
} */
.galleryHeading{
    color: white;
    font-family: 'Samarkan';
}
.showsImage{
    width: 530px;
    height:300px;
}
.boxDiv:hover{
    box-shadow: 0px 0px 30px rgb(175, 137, 0);
}
#shield{
    transition: all;
    transition-duration: 0.8s;
    transition-behavior: ease-out;
    width: 208px;
    height: 208px;
}
.horse3{
    filter: invert(0.7);
    width: 148px;
    transition: all 0.5s ease;
}
.horse3:hover{
    transform: scale(1.1);
   }
   
@media(max-width: 600px){
 .gallery{   
    height:70vh;
    /* background-color: azure; */
}   
.showsImage{
    width: 74vw;
    height:200px;
}
.shield{
    /* width: 50vw;
    height: 50vw;*/
    display: none;
}
.galleryHeading{
    font-size: 45px;
}
.horse3{
width:64px
}
}
