.mapFrame{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.mapContainer{
    height: 68vh;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: end;
    border-radius: 12px;
}

.map{
    width: 60vw;
    height: 75vh;
    border-radius: 12px;
}