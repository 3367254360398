.main {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden; /* Hide overflow if needed */
    background-color: black;
}

.video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    transform: translate(-50%, -50%); /* Center the video */
}

.video {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the entire container */
}
.videoButton{
    border: 2px solid gray;
    transition: transform 500ms ease;
}
.videoButton:hover{
   transform: scale(1.1);
   box-shadow: 2px 2px 10px gray;
}