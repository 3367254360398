.cardAfterMovies3 {
    width: 320px;
    height: 56vh;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    border-radius: 0.5rem;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 500ms ease-out, box-shadow 500ms ease-in-out;
    overflow: hidden;
}

.cardAfterMovies3:hover {
    transform: translateY(-10%);
    cursor: pointer;
    box-shadow: 0px 0px 30px rgb(188, 147, 0);
}

.cardAfterMovies3::before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    width: 60%;
    height: 100%;
    top: 0;
    left: -150%;
    transform: skew(45deg);
    transition: left 600ms ease-out;
}

.cardAfterMovies3:hover::before {
    left: 180%;
}

.youtubeIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0s 0.5s;
}

.youtubeIcon img {
    width: 80px;
    transition: all 0.5s ease-in-out;
}

.cardAfterMovies3:hover .youtubeIcon {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0s 0s;
}

.heading h1 {
    font-size: 48px;
}

.afterMovies {
    display: flex;
    flex-direction: column;
}

.afterMoviesHeading {
    font-family: 'Samarkan';
}

.horse2 {
    filter: invert(0.7);
    width: 148px;
    transition: all 0.5s ease;
}

.horse2:hover {
    transform: scale(1.1);
}

@media (min-width: 1200px) {
    .afterMovies {
        flex-direction: row;
    }
}

@media (min-width: 1050px) and (max-width: 1200px) {
    .cardAfterMovies3 {
        width: 33vw;
        height: 60vh;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .afterMovies {
        flex-direction: row;
    }

    .heading h1 {
        font-size: 56px;
    }
}

@media (min-width: 600px) and (max-width: 1050px) {
    .cardAfterMovies3 {
        width: 33vw;
        height: 56vh;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .afterMovies {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) {
    .cardAfterMovies3 {
        width: 65vw;
        height: 33vh;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .cardAfterMovies3:hover {
        transform: translateY(0%);
        cursor: pointer;
    }

    .anchorTag {
        width: 65vw;
    }

    .heading h1 {
        font-size: 44px;
    }

    .afterMoviesHeading {
        font-size: 45px;
    }

    .horse2 {
        width: 64px;
    }
}