* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.antiq-back-img{
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.topBackground{
  width:100%;
  height: 80px;
  position: fixed;
  top:0;
  z-index: 0;
  background-color: rgba(0,0,0,0.8);
}
.antiq {
  width: 100%;
  height: auto;
  padding: 180px 0px 0px 0px;
  display: grid;
  place-items: center;

  
}
.antiq .headed {
  margin: -50px 0px 20px 0px;
  font-size: 40px;
  font-weight: 800;
 
  position: relative;
}

.headed {
    font-size: 72px;
    background-color:  #402201;
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
}
.headed::after {
  position: absolute;
  content: " ";
  width: 50%;
  height: 5px;
  left: 50%;
  bottom: -5px;
  background-color:  #402201;
  transform: translateX(-50%);
}
.contender {
  width: 70%;
  height: 100%;
  position: relative;
}
.contender::after {
  position: absolute;
  content: " ";
  left: 50%;
  top: 3%;
  width: 2px;
  height: 97%;
  background-color:  #402201;
  /* overflow: hidden; */
}
.contender ul {
  list-style: none;
}
.contender ul li {
  width: 50%;
  height: auto;
  padding: 15px 20px;
  background: #EFE7DD38;

  box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.384);
  border-radius: 10px;
  margin-bottom: 30px;
  z-index: 10;
  position: relative;
  margin: 80px 0px;
}
.contender ul li:nth-child(odd) {
  float: left;
  clear: right;
  text-align: right;
  transform: translateX(-60px);
}
.contender ul li:nth-child(odd) .date {
  right: 20px;
}
.contender ul li:nth-child(even) {
  float: right;
  transform: translateX(60px);
  clear: left;
  text-align: left;
}
.contender ul li .titler {
  font-size: 20px;
  font-weight: 500;
  color: #402201;
}
ul li p {
  font-size: 15px;
  color: #444;
  margin: 7px 0;
  line-height: 23px;
}
ul li .date {
  position: absolute;
  top: -40px;
  
  width: 140px;
  height: 35px;
  border-radius: 20px  ;
  border-color: #40220175;
  color: #fff;
  background-color:  #402201;
  border-width: 3px;
  display: grid;
  place-items: center;
  font-size: 16px;
}
.contender ul li .circle {
  width: 30px;
  height: 30px;
  background: #40220175;

  border-radius: 50%;
  position: absolute;
  top: -14%;
}
.contender ul li .circle::after {
  content: " ";
  width: 15px;
  height: 15px;
  background-color: #402201;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contender ul li:nth-child(odd) .circle {
  right: -60px;
  transform: translate(50%, -50%);
}
.contender ul li:nth-child(even) .circle {
  left: -60px;
  transform: translate(-50%, -50%);
}


@media screen and (max-width: 1224px) {
  .contender {
    width: 93%;
  }
}
@media screen and  (min-width: 700px)and (max-width: 899px) {
  .contender {
    width: 80%;
    transform: translateX(35px);
  }
  .contender ul li {
    width: 100%;
    float: none;
    clear: none;
    margin-bottom: 80px;
  }
  .contender ul li:nth-child(odd) {
    transform: translateX(-10px);
    text-align: left;
  }
  .contender ul li:nth-child(odd) .date {
    left: 20px;
  }
  .contender ul li:nth-child(odd) .circle {
    left: -40px;
    transform: translate(-50%, 0%);
  }
  .contender ul li:nth-child(even) .circle {
    left: -40px;
    transform: translate(-50%, 0%);
  }
  .contender ul li:nth-child(even) {
    transform: translateX(-10px);
  }
  .contender::after {
    left: -50px;
  }
  .antiq .headed {
    margin: -20% 0px 13% 0px;
    font-size: 40px;
    font-weight: 800;
    
    position: relative;
  }
  .contender ul li .circle {
    width: 30px;
    height: 30px;
    background-color: #40220175;
    border-radius: 50%;
    position: absolute;
    top: -30%;
  }
  
}
@media screen and (max-width: 700px) {
  .contender {
    width: 80%;
    transform: translateX(35px);
  }
  .contender ul li {
    width: 100%;
    float: none;
    clear: none;
    margin-bottom: 80px;
  }
  .contender ul li:nth-child(odd) {
    transform: translateX(-10px);
    text-align: left;
  }
  .contender ul li:nth-child(odd) .date {
    left: 20px;
  }
  .contender ul li:nth-child(odd) .circle {
    left: -40px;
    transform: translate(-50%, 0%);
  }
  .contender ul li:nth-child(even) .circle {
    left: -40px;
    transform: translate(-50%, 0%);
  }
  .contender ul li:nth-child(even) {
    transform: translateX(-10px);
  }
  .contender::after {
    left: -50px;
  }
  .antiq .headed {
    margin: -20% 0px 13% 0px;
    font-size: 40px;
    font-weight: 800;
    
    position: relative;
  }
  .contender ul li .circle {
    width: 30px;
    height: 30px;
    background-color: #40220175;
    border-radius: 50%;
    position: absolute;
    top: -9%;
  }
  .contender::after {
  
    top: 3%;
   
    height: 97%;
   
}
  
}


