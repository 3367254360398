@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;800&display=swap");

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
}


  

main {
    height: 100vh;
    position: relative;
    width: 100vw;
    overflow: hidden;
    background-color: black;
}

.parallax {
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: all 0.45s cubic-bezier(.2, .49, .32, .99);
}

main .bg-img {
    min-width: 1650px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50%);
    z-index: 1;
    /* transform: translate(-50%, -50%); */
}

main .fog-7 {
    width: 1900px;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% + 300px);
    /* transform: translate(-50%, -50%); */
    z-index: 2;
}

main .player-1 {
    position: absolute;
    z-index: 4;
    width: 1092px;
    top: calc(50% + 50px);
    left: calc(50% - 20px);
    /* transform: translate(-50%, -50%); */
}
main .player-2 {
    position: absolute;
    z-index: 4;
    width: 1092px;
    top: calc(50% + 50px);
    left: calc(50% - 90px);
    /* transform: translate(-50%, -50%); */
}
main .player-3 {
    position: absolute;
    z-index: 4;
    width: 1092px;
    top: calc(50% + 40px);
    left: calc(50% + 40px);
    /* transform: translate(-50%, -50%); */
}
main .player-4 {
    position: absolute;
    z-index: 22;
    filter: brightness(50%);
    width: 1092px;
    top: calc(50% + 40px);
    left: calc(50% + 60px);
    /* transform: translate(-50%, -50%); */
}
main .player-6 {
    position: absolute;
    z-index: 4;
    width: 1200px;
    top: calc(50% + 40px);
    left: calc(50% - 40px);
    /* transform: translate(-50%, -50%); */
}
main .typeface {
    position: absolute;
    z-index: 20;
    width: 850px;
    top: calc(50%);
    filter: contrast(100%);
    /* filter:brightness(100%); */
    left: calc(50%);
    /* transform: translate(-50%, -50%); */
}
main .blackLayer{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.60);
    z-index: 10;
}
::selection {
    background-color: #0b372dbb;
}

main .fog-4 {
    width: 590px;
    position: absolute;
    top: calc(50% + 260px);
    left: calc(50% - 350px);
    /* transform: translate(-50%, -50%); */
    z-index: 4;
}

main .fog-3 {
    width: 1590px;
    position: absolute;
    top: calc(50% + 110px);
    left: calc(50% + 515px);
    /* transform: translate(-50%, -50%); */
    z-index: 4;
}
main .sun-rays {
    position: absolute;
    right: -100px;
    top: -100px;
    width: 595px;
    pointer-events: none;
    z-index: 21;
}
main .sun-rays2 {
    position: absolute;
    left: -150px;
    top: -150px;
    width: 595px;
    transform: scaleY(-1) scaleX(1) rotatez(180deg);
    pointer-events: none;
    z-index: 21;
}

main .black-shadow {
    position: absolute;
    top:calc(100%);
    width: 100vw;
    /* height: 150vh; */
    z-index: 10;
    pointer-events: none;
}

main .fog-1 {
    width: 1600px;
    position: absolute;
    top: calc(50% + 650px);
    left: calc(50% + 100px);
    /* transform: translate(-50%, -50%); */
    z-index: 4;
}

main .vignette {
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.7));
}
/* .blend {
    width: 100vw;
    height: 30px;
    background: linear-gradient(to bottom, #4a8e34, #a1987b); 
} */



