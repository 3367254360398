body,
html {
    height: 100%;
}

.teams-container {
    background-image: url("/public/commonbg.webp");
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;

}

.teams-overlay{
    height: 100%;
    width: 100vw;
    position: absolute;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    background-size: contain;
}

.teams-list {
    position: sticky;
    z-index: 20;
}

.teams-filled-half {
    height: 30vh;
    width: 30vh;
    border-radius: 50%;
    background-color: black;
    position: fixed;
    top: 40vh;
    left: -15vh;
}

.teams-mid-arc {
    height: 40vh;
    width: 40vh;
    border-radius: 50%;
    position: fixed;
    top: 35vh;
    border: 10px solid burlywood;
    left: -20vh;
}

.teams-out-arc {
    height: 110vh;
    width: 110vh;
    border-radius: 50%;
    position: fixed;
    top: 0vh;
    border: 3px solid black;
    left: -55vh;
    z-index: -2;

}

.teams-verti {
    color: rgb(215, 185, 159);
}

.teams-verti * {
    font-weight: 700;
    /* color: white; */
}

.teams-headings .active {
    color: white;
    font-size: larger;
}

.teams-enc {
    position: fixed;
    top: 27vh;
    left: 8vh;
}

.teams-hospi {
    position: fixed;
    top: 31.5vh;
    left: 13vh;
}

.teams-mark {
    position: fixed;
    top: 36vh;
    left: 16vh;
}

.teams-pr {
    position: fixed;
    top: 41vh;
    left: 19vh;
}

.teams-mnp {
    position: fixed;
    top: 45.5vh;
    left: 21vh;
}

.teams-des {
    position: fixed;
    top: 55.5vh;
    left: 23vh;
}

.teams-wna {
    position: fixed;
    top: 50.5vh;
    left: 23vh;
}

.teams-sm {
    position: fixed;
    top: 60.5vh;
    left: 21vh;
}

.teams-fin {
    position: fixed;
    top: 65.5vh;
    left: 18vh;
}

.teams-sec {
    position: fixed;
    top: 70.5vh;
    left: 13vh;
}

.teams-fc {
    position: fixed;
    top: 75.5vh;
    left: 8vh;
}

.teams-content {
    /* background-color: aquamarine; */
    position: relative;
    padding: 0 0 30vh 0;
    left: 30vw;
    width: 60%;
    z-index: 20;
}

.teams-content-film {
    /* background-color: grey; */
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    filter: blur(10px);
    opacity: 0.6;
    border-radius: 10vh;

}

.teams-header {
    /* background-color: blueviolet; */
    height: 8vh;
    font-size: 37px;
    font-weight: 700;
    position: relative;
    top: 15vh;
    /* z-index: 5; */
}

.teams-about {
    /* height: 73vh; */
    /* background-color: blueviolet; */
    display: flex;
    position: relative;
    top: 15vh;
    flex-direction: column;
    justify-content: space-around;
    padding: 10vh 0;

}

.teams-title {
    height: 7vh;
    /* background-color: blueviolet; */
    height: fit-content;
    font-size: 30px;
    font-weight: 600;
    width: 60%;
    align-self: center;
    border-radius: 4vh;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    margin-top: 30px;
}

.teams-all-photos {
    padding-top: 40px;
    display: flex;
    justify-content: space-around;

}

.teams-head-photo {
    height: 200px;
}

.teams-photos {
    width: 20%;
    position: relative;
    display: flex;
    flex-direction: column;

}

.teams-photos img {
    border-radius: 10px;
    /* width: 13vw; */
    object-fit: cover;
    transition: 0.8s;
}

.teams-head-photo:hover {
    scale: 1.1;
    transition: 0.8s;
}

.teams-firstname {
    font-size: 23px;
    font-weight: 500;
    white-space: pre-line;

}

.teams-lastname {
    position: absolute;
    bottom: -2vh;
    right: -2vh;
    color: black;
    font-size: 23px;
    font-weight: 500;
}

.teams-socials-row {
    /* background-color: blue; */
    display: flex;
    justify-content: space-around;
}

.teams-socials {
    /* width: 20%; */
    padding-top: 20px;
}

.teams-heads-socials {
    display: flex;
    /* background-color: chartreuse; */
    /* width: 20%; */
    justify-content: space-evenly;
    transition: 0.6s;
}

.teams-heads-socials i:hover {
    scale: 1.2;
    transition: 0.6s;
}

.teams-socials-line {
    height: 5px;
    background-color: black;
}

/* ................... */
.box {
    width: 200px;
    height: 200px;
    background-color: transparent;
    border-radius: 14px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    transition: all 0.5s ease;
    transition: transform 0.1s ease-out;
    transform-style: preserve-3d;
    perspective: 1000px;
}

.box .imagecontent {
    width: 200px;
    height: 200px;
    padding-top:24px;
    background-color:rgb(54, 37, 17);
    border-radius: 14px;
    position: relative;
    z-index: 2;
    transform: translateZ(20px);
    box-shadow: 8px 8px 20px rgb(33, 33, 33);
}

.box .backBox {
    width: 200px;
    height: 200px;
    border-radius: 14px;
    z-index: 1;
    transition: all 0.5s ease-out;
    perspective: 1000px;
}

.box:hover .backBox {
    width: 300px;
    height: 300px;
}
@media (max-width:600px){
    .box:hover .backBox {
        width: 320px;
        height: 320px;
    }
    .teams-header{
        font-size: 44px;
    }
    .box {
        width: 220px;
        height: 220px;
    }
    
    .box .imagecontent {
        width: 220px;
        height: 220px;
    }
    .box .backBox {
        width: 220px;
        height: 220px;
    }
}
.box .icons{
    transform-style: preserve-3d;
}
.icons i {
    color: white;
    font-size: 22px;
    transform: translateY(0px) translateZ(40px);
    transition: all 0.5s ease;
}
.icons i:hover{
    cursor: pointer;
}
.imagecontent .imageMember{
    width: 120px;
    height: 120px;
    
}
.imagecontent .imageMember img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    transform: scale(1.1);
}
/* ................... */


@media(max-width: 1200px) {
    .teams-list {
        display: none;
    }

    .teams-content {
        width: 100%;
        left: 0vw;
    }


}


@media(max-width: 600px) {
    .teams-content {
        top: 10vh;
    }

    .teams-title {
        width: 80%;
        height: fit-content;
    }

    .teams-all-photos {
        flex-direction: column;
        align-items: center;
    }

    .teams-photos {
        width: 60%;
        margin-top: 20px;
    }

    .teams-head-photo {
        height: 250px;
    }

    .teams-socials i {
        font-size: x-large;
    }
}