@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Inter:wght@200&display=swap');

.sponsors-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
}

.sponsors-back {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -2;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(0, 0, 0, 0.7)), url(https://live.staticflickr.com/65535/53236930531_a4428b3c84_b.jpg);
    background-size: cover;
    background-blend-mode: darken;
}

.sponsors-leftpart {
    width: 65vw;
}

.sponsors-header {
    height: 80vh;
    width: 90%;
    top: 100px;
    left: 10px;
    position: sticky;

}

.sponsors-header-frame {
    position: absolute;
    height: 90%;
    aspect-ratio: 652/381;
    /* object-fit:fill; */
    z-index: -1;
}

.sponsors-heading {
    position: absolute;
    top: 25%;
    left: 15%;
    font-size: 2.5rem;
    color: rgb(255, 235, 51);
    font-family: 'Balsamiq Sans', cursive;
}

.sponsors-para {
    position: absolute;
    padding-right: 30px;
    height: 40vh;
    top: 40%;
    left: 15%;
    line-height: 38px;
    font-weight: 700;
    color: white;
    text-align: left;
    font-family: 'Inter', sans-serif;
}

.sponsors-rightpart {
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    width: 35vw;
    height: fit-content;
    position: absolute;
    align-items: center;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
}

.sponsor-trail-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    /* justify-content: center; */
}

.sponsors-trail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.sponsors-trail-1 {
    border-left: solid white 2px;
    border-right: solid white 1px;
}

.sponsors-trail-2 {
    border-left: solid white 1px;
    border-right: solid white 2px;
}

.sponsor-heading {
    text-align: start;
    color: white;
    font-family: 'Balsamiq Sans', cursive;
    font-size: 18px;
}

.sponsor-white-bar {
    border: solid white 1px;
    background-color: white;
    /* position: fixed; */
    width: 2px;
    height: 100vh;
}

.sponsor-img-frame {
    width: 100%;
    height: 80%;
    max-height: 80%;
    /* background: rgba(183, 99, 3, 0.4);
    border: 3px solid rgb(183, 99, 3,1); */
    background: rgb(255, 255, 255,0.4);
    border: 3px solid rgb(88, 130, 193, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 70%; */
    aspect-ratio: 1/1;
}

.sponsor-img-frame img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 900px) {
    .sponsors-container {
        flex-direction: column;
        overflow: hidden;
    }

    .sponsors-leftpart {
        position: static;
        width: 100%;
        height: 50vh;
    }

    .sponsors-rightpart {
        padding-top: 20vh;
        margin-left: 0;
        position: relative;
        width: 100%;
    }

    .sponsor-trail-container {
        justify-content: center;
    }

    .sponsors-para {
        top: 35%;
        line-height: 30px;
    }

    .sponsors-header-frame {
        width: 100%;
    }
}