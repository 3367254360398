* {
    margin: 0;
    padding: 0;
}

.coloredElement {
    background-color: rgb(147, 97, 11);
}

.smallScreenLogo {
    width: 40vw;
}

.typefaceHref{
    width: 80%;
}

@media(max-width:450px) {
    .smallScreenLogo {
        width: 70vw
    }
}
.typefaceHref1 img{
  filter: invert(0.9);
  margin-top: 8px;
}
.typefaceHref2 img{
  filter: invert(0.9);
  margin-top: 8px;
}
nav a {
    text-decoration: none;
    color: aliceblue;
    font-size: 17px;
    padding: 4px 8px;
    position: relative;
    opacity: 1;
}

.menu__link {
    font-size: 17px;
}

.typeface {
    width: 100%;
}

.leftLinks {
    gap: 16px
}

.rightLinks {
    gap: 16px;
}

@media(min-width: 1000px) {
    .typeface {
        width: 100%;
    }

    .menu__link {
        font-size: 14px;
    }

    nav a {
        font-size: 14px;
    }
}
@media(min-width: 1200px) {
    .typeface {
        width: 95%;
        /* margin-left: 10px; */
    }

    .menu__link {
        font-size: 18px;
    }

    nav a {
        font-size: 18px;
    }
}

@media(min-width: 1300px) {
    nav a {
        font-size: 18px
    }

    .menu__link {
        font-size: 18px;
    }

    .typeface {
        width: 85%;
        /* margin-left: 36px; */
    }

    .leftLinks {
        gap: 49px
    }

    .rightLinks {
        gap: 49px;
    }
}

nav a:hover::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: 2;
    padding: 4px;
    border-bottom: 2px solid white;
    animation: animate 0.5s ease-out;
}
nav .typefaceHref::after{
    opacity: 0;
}
nav .noHover:hover::after {
    animation: none;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: 2;
    padding: 4px;
    border-bottom: none;
}

@keyframes animate {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

.dropdownIcon {
    font-size: 14px;
    margin-top: 4px;
}